<template>
  <div>
    <el-row class="color-w p-l-20 p-r-20" style="height: 100%">
      <el-button
        type="text"
        @click="editGid(item)"
        style="width: 100%"
        class="text-l color-w p-l-10 p-r-10 m-t-15 f-s-22"
        >目录-总{{ numberfilter(catalList[0].length) }}</el-button
      >
      
      <el-scrollbar class="catalList">
        <el-col :span="24" v-for="(item, k) in catalList[0]" :key="k">
          <el-button
            type="text"
            @click="editGid(item)"
            style="width: 100%; background: #2f3236;white-space:normal;padding-top:3px;line-height:30px;padding-bottom:3px"
            class="text-l color-w p-l-10 p-r-10 m-t-6 f-s-16"
            :loading="item.gType"
          >
          <span class="f-l-l">第{{ numberfilter(k + 1) }}:</span>
            <p
              style="word-break:break-all;line-height:30px"
              class="f-l-l"
              :style="titleWidth(k)"
              >{{ item.gname }}</p
            >
            <!-- <span>共{{item.vlist.length}}小结</span> -->
            <i
              class="el-icon--right f-l-r m-t-10"
              :class="
                item.gid == gid ? 'el-icon-arrow-down' : 'el-icon-arrow-right'
              "
            ></i
          ></el-button>
          <!-- :style="[{ background: activeVid == pojo.vid ? 'white' : '' }]" -->
          <div
            v-for="(pojo, index) in item.vlist"
            :key="index"
            @click="getClassInfo(pojo, item)"
            style="line-height: 19px"
            class="p-l-10 p-r-10 f-s-14"
            :style="[{ display: gid == item.gid ? 'block' : 'none' }]"
          >
            <p
              class="overflowHiding cu m-t-15"
              :style="[{ color: vid == pojo.vid ? '#03a0d6' : '' }]"
              :id="'v' + pojo.vid"
              :title="pojo.vname"
            >
            <i class="el-icon-circle-check color-or f-l-l m-t-3 m-r-5" v-if="pojo.watchType && pojo.vid != vid"></i>
              <el-image
                :src="playerLeftImg"
                style="width: 20px"
                v-if="vid == pojo.vid"
              ></el-image>
              <span
                style="width: 65%; display: inline-block"
                class="overflowHiding"
                >{{ index + 1 }}.{{ pojo.vname }}</span
              >
              <span class="f-l-r">{{
                pojo.vid == vid ? "正在播放" : pojo.timelength
              }}</span>
            </p>
          </div>
        </el-col>
        <p>.</p>
      </el-scrollbar>
      <!-- </div> -->
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    catalList: {
      type: Array,
      // default: () => {
      //   return [];
      // },
    },
    gid: {
      type: Number,
    },
    vid:{
      type:Number
    },
    totalSummary:{
      type:Number
    },
    learnFin:{
      type:Number
    },
    getGidInfoType:{
      type:Boolean,
    }
  },
  computed: {
    // activeVid() {
    //   if (this.$route.query.vid == null || this.$route.query.vid == undefined) return this.$parent.vid;
    //   return this.$route.query.vid;
    // },
    skipTrailerInfo() {
      return this.$store.state.overall.skipTrailerInfo;
    },
    videoNum() {
      return this.$store.state.overall.videoNum;
    },
  },
  data() {
    return {
      playerLeftImg: require("@/assets/img/player/playing.gif"),
    };
  },
  methods: {
    titleWidth(k){
      let index = this.numberfilter(k + 1)
      if(index.length >= 3) {
        return 'width:60%'
      } else if(index.length >= 4) {
        return 'width:70%'
      } else if(index.length >= 5) {
        return 'width:60%'
      } else {
        return 'width:70%'
      }
    },
    numberfilter(num) {
      const changeNum = [
        "零",
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
        "七",
        "八",
        "九",
      ]; // changeNum[0] = "零"
      const unit = ["", "十", "百"];
      num = parseInt(num);
      const getWan = (temp) => {
        const strArr = temp.toString().split("").reverse();
        let newNum = "";
        for (var i = 0; i < strArr.length; i++) {
          newNum =
            (i == 0 && strArr[i] == 0
              ? ""
              : i > 0 && strArr[i] == 0 && strArr[i - 1] == 0
              ? ""
              : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i])) +
            newNum;
        }
        return newNum;
      };
      const overWan = Math.floor(num / 100);
      let noWan = num % 100;
      if (noWan.toString().length < 2) noWan = "0" + noWan;
      let strr = overWan ? getWan(overWan) + "一百" + getWan(noWan) : getWan(num);
      if (strr.split("")[0] == "一") {
        let showNum = "";
        if (strr == "一") {
          showNum = strr.substring(0);
        } else {
          showNum = strr.substring(1);
        }
        return showNum + "章";
      } else {
        let showNum = overWan
          ? getWan(overWan) + "百" + getWan(noWan)
          : getWan(num);
        return showNum + "章";
      }
    },
    editGid(item) {
      if (item.gid == this.gid) return false;
      this.$emit("editGid", item);
    },
    getClassInfo(pojo, item) {
      if (!this.$f.uid()) {
        this.$message.error("请先登录");
        return false;
      }
      this.$emit("getClassInfo", pojo.vid, item.gid, "1");
    },
    replaceVideo() {
      for (const key in this.catalList[0]) {
        if (this.catalList[0][key].gid == this.skipTrailerInfo.gid) {
          for (const i in this.catalList[0][key].vlist) {
            if (
              this.catalList[0][key].vlist[i].vid == this.skipTrailerInfo.vid
            ) {
              if (this.catalList[0][key].vlist[i * 1 + 1] == undefined) {
                if (this.catalList[0][key * 1 + 1] == undefined) {
                  this.$message.error("没有更多视频可以观看啦");
                } else {
                  this.getClassInfo(this.catalList[0][key * 1 + 1].vlist[0],this.catalList[0][key * 1 + 1]);
                }
              } else {
                this.getClassInfo(this.catalList[0][key].vlist[i * 1 + 1],this.catalList[0][key]);
              }
              break;
            }
          }
        }
      }
    },
  },
  watch: {
    vid: {
      deep: true,
      immediate: true,
      handler(val) {
        this.$nextTick(() => {
          if (
            val == "" ||
            val == undefined ||
            val == null ||
            this.catalList.length == 0
          )
            return false;
          document.getElementById("v" + val).scrollIntoView();
        });
      },
    },
    // catalList: {
    //   deep: true,
    //   immediate: true,
    //   handler() {
    //     this.$nextTick(() => {
    //       if (
    //         this.vid == "" ||
    //         this.vid == undefined ||
    //         this.vid == null ||
    //         this.catalList.length == 0
    //       )
    //         return false;
    //       document.getElementById("v" + this.vid).scrollIntoView();
    //     });
    //   },
    // },
    videoNum: {
      deep: false,
      immediate: false,
      handler(val) {
        if (val == 0) return false;
        this.replaceVideo();
      },
    },
  },
};
</script>

<style>
.el-scrollbar__wrap {
  overflow-x: hidden !important;
}
</style>

<style scoped>
.classTitle {
  background: #2f3236;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  color: white;
  padding-left: 10px;
}
p {
  margin: 0;
}
.catalList {
  height: calc(100% - 50px);
}
</style>