<template>
  <div  @contextmenu.prevent="selectBtn" style="height: 100%; background: #000000;overflow-x: hidden;overflow-y: hidden">
    <!-- class="xz-b" -->
    <div style="height: 100%">
      <el-row class="text-l m-b-20" style="height: 100%">
        <el-col
          :span="20"
          style="background: #000000; max-height: 100%"
          :style="[{ width: catalType ? '80%' : '100%' }]"
        >
          <p class="playLeft h-80 p-l-10 videoTitle text-c">
            <el-button
              type="text"
              icon="el-icon-arrow-left"
              class="f-l-l color-w"
              style="height: 80px"
              @click="back"
              >返回</el-button
            >
            {{ videoTitle }}
            <i
              class="el-icon-arrow-right f-l-r f-s-24 h-80 cu"
              style="background: #2f3236"
              @click="catalListType"
              :class="catalType ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"
            ></i>
          </p>
            <!-- @editVideoInfo="editVideoInfo" -->

          <PlayVideo
            ref="playvideo"
            :playerOptions="playerOptions"
            :cid="cid"
            :vid="vid"
            :gid="gid"
            :timer="timer"
            :style="[{ width: !catalType ? '80%' : '100%' }]"
            style="margin: 0 auto"
            :totalSecond="totalSecond"
            @editVideoTime="editVideoTime"
          />
        </el-col>
        <el-col
          :span="4"
          class="rightCata"
          style="height: 100%; width: 20%"
          :style="[{ display: catalType ? 'block' : 'none' }]"
        >
        <!-- p-t-10 -->
          <p class="playRight h-80  text-l overflowHiding">{{ className }}</p>
          <!-- <p class="playRight p-b-12 text-l overflowHiding f-s-12">(总{{ totalSummary }}小结-已学{{ learnFin }})</p> -->
          <!-- class="yichu" -->

          <Catal
            :catalList="catalList"
            @getClassInfo="getClassInfo"
            style="
              background: #3f444a;
              overflow: hidden;
              height: calc(100% - 80px);
            "
            :gid="gid"
            :vid="vid"
            @editGid="editGid"
            :totalSummary="totalSummary"
            :learnFin="learnFin"
            ref="catal"
            :getGidInfoType="getGidInfoType"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
// import Bread from "@/components/player/bread";
import Catal from "@/components/player/catal";
import PlayVideo from "@/components/public/PlayVideo/playVideo";
export default {
  components: {
    // Bread,
    Catal,
    // videoPlayer,
    PlayVideo,
  },
  data() {
    return {
      getGidInfoType:false,
      videoTitle: "",
      playerOptions: {
        playbackRates: [0.5, 0.8, 1.0, 1.25, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 类型
            src: "http://webxiaozhu.xzjy365.com/Course/%E4%BF%AE%E6%94%B9%E5%90%8E02-%E7%8E%B0%E5%9C%A8%E4%B8%8D%E5%AD%A6BIM%E5%B0%86%E6%9D%A5%E5%8F%91%E5%B1%95%E5%A5%BD%E5%90%97.mp4", // url地址
          },
        ],
        poster:
          "http://webxiaozhu.xzjy365.com/images/vCover4Tf0gS", // 封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: true, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
      catalList: [[]],
      cid: "",
      vid: "",
      gklog: 0,
      gid: "",
      timer: 0,
      catalType: true,
      totalSecond: 0,
      className: "",
      learnFin: 0,
      totalSummary: 0,
    };
  },
  created() {
     document.oncontextmenu = function() {
        return false;
      };
    let query = this.$route.query;
    this.cid = query.cid * 1;
    this.vid = query.vid * 1;
    this.getCatalData();
    if (this.vid != null) this.getClassInfo();
  },
  methods: {
    catalListType() {
      this.catalType = !this.catalType;
    },
    editGid(item) {
      if(!this.$f.isNotNull(item.vlist)) {
        item.gType = true
        let data = {
          cid : this.cid,
          gid : item.gid,
          uid : this.$f.uid()
        }
        this.Api.Center.getVideoListByGid(data).then(res => {
          if(res.status == 200) {
            item.vlist = res.data.data
            this.gid = item.gid;
          } else {
            this.$message.error("获取章节信息失败,请重试")
          }
          item.gType = false
        }).catch(() => {
          this.$message.error("发生未知错误,请重新尝试")
          item.gType = false
        })
      } else {
        this.gid = item.gid;
      }
    },
    back() {
      this.$router.push({
        path: "/learningCenter",
      });
      // window.history.back();
    },
    onContextMenu(event) {
      console.log(event);
    },
    editVideoTime(data) {
      for (const key in this.catalList[0]) {
        if (this.catalList[0][key].gid == data.gid) {
          for (const i in this.catalList[0][key].vlist) {
            if (this.catalList[0][key].vlist[i].vid == data.vid) {
              this.catalList[0][key].vlist[i].maxprogress = data.videoprogress;
              let hour = this.catalList[0][key].vlist[i].timelength.substring(0, 2);
              let minute = this.catalList[0][key].vlist[i].timelength.substring(3, 5);
              let second = this.catalList[0][key].vlist[i].timelength.substring(6, 8) * 1;
              this.catalList[0][key].vlist[i].totalTime = hour * 60 * 60 + minute * 60 + second;
              if (this.catalList[0][key].vlist[i].totalTime <this.catalList[0][key].vlist[i].maxprogress + 5 ) {
                this.learnFin += 1;
                this.catalList[0][key].vlist[i].watchType = true;
              } else {
                this.catalList[0][key].vlist[i].watchType = false;
              }
            }
          }
        }
      }
    },
    // 获取视频的信息
    getClassInfo(vid, gid, type) {
      if (this.$refs.playvideo != undefined) this.$refs.playvideo.num = 0;
      if (type != undefined) {
        this.$refs.playvideo.addUserVideoStatusAndLog();
      }
      let query = this.$route.query;
      if (vid != undefined)
        this.$router.push({
          query: {
            vid,
            cid: query.cid,
            gid,
          },
        });
      let data = {
        vid: vid != undefined ? vid : query.vid,
        cid: query.cid,
        gid: gid == undefined ? query.gid : gid,
      };
      this.gid = data.gid * 1;
      this.Api.Center.getClassDetial(data).then((res) => {
        if (res.data == "暂未购买课程，不可观看") {
          this.$alert("暂未购买课程,请先购买", "提示", {
            confirmButtonText: "确定",
            callback: () => {
              this.$router.push({
                path: "/course/toOneCenter",
                query: { cid: this.$route.query.cid },
              });
            },
          });
          return false;
        } else if (res.error == "无token，请重新登录") {
          this.$f.goLogon();
          return false;
        }
        // else if (query.vid != null || vid != undefined || this.vid != "") {
        let msg = res.data;
        let hour = msg.timelength.substring(0, 2);
        let minute = msg.timelength.substring(3, 5);
        let second = msg.timelength.substring(6, 8) * 1;
        this.totalSecond = hour * 60 * 60 + minute * 60 + second;
        this.playerOptions.sources.src = msg.vurl;
        this.videoTitle = msg.vname;
        this.className = msg.className;
        this.vid = msg.vid * 1;
        this.gid = msg.gid;
        this.$refs.playvideo.showVideo();
        if (msg.latelyprogress > 0) this.timer = msg.latelyprogress;
        // let localVideoInfo = this.$f.getLocal(this.cid);

        // if (localVideoInfo == null) {
        //   return;
        // } else {
        //   for (let i = 0; i < localVideoInfo.info[0].length; i++) {
        //     for (let j = 0; j < localVideoInfo.info[0][i].vlist.length; j++) {
        //       if (localVideoInfo.info[0][i].vlist[j].vid == this.vid) {
        //         this.gklog = localVideoInfo.info[0][i].vlist[j].recordTime;
        //         if (this.gklog > 0) {
        //           this.timer = this.gklog;
        //           // this.$refs.playvideo.timer = this.gklog;
        //         }
        //         break;
        //       }
        //     }
        //   }
        // }
        // }
      });
    },
    // 获取目录
    getCatalData() {
      // let quert = this.$route.query;
      let data = {
        cid: this.cid,
        uid: this.$f.uid(),
      };
      this.Api.Curr.getCourseCatalogue(data)
        .then((res) => {
          res.data.data[0].forEach((item) => {
            this.totalSummary += item.vlist.length;
            item.vlist.forEach((pojo) => {
              pojo.recordTime = 0;
              pojo.watchNum = 0;
              let hour = pojo.timelength.substring(0, 2);
              let minute = pojo.timelength.substring(3, 5);
              let second = pojo.timelength.substring(6, 8) * 1;
              pojo.totalTime = hour * 60 * 60 + minute * 60 + second;
              if (pojo.totalTime < pojo.maxprogress + 5) {
                this.learnFin += 1;
                pojo.watchType = true;
              } else {
                pojo.watchType = false;
              }
            });
          });
          this.catalList = res.data.data;
        })
        .catch(() => {
          this.$message.error("获取视频目录超时,请重试");
        });
    },
    // 修改本地缓存数据
    // editVideoInfo(time) {
    //   let localVideoInfo = this.$f.getLocal(this.cid);
    //   for (let i = 0; i < localVideoInfo.info[0].length; i++) {
    //     for (let j = 0; j < localVideoInfo.info[0][i].vlist.length; j++) {
    //       if (
    //         localVideoInfo.info[0][i].vlist[j].vid == this.vid &&
    //         localVideoInfo.info[0][i].vlist[j].recordTime < time
    //       ) {
    //         localVideoInfo.info[0][i].vlist[j].recordTime = time;
    //         this.$f.setLocal(this.cid, localVideoInfo);
    //         return;
    //       }
    //     }
    //   }
    // },
  },

};
</script>

<style scoped>
.playLeft {
  /* border: solid 1px #e4e4e4; */
  border-right: none;
  border-left: none;
}
.playRight {
  /* border: solid 1px #e4e4e4; */
  border-left: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 30px;

  background: #2f3236;
  color: white;
}
.videoTitle {
  margin-bottom: 0;
  margin-top: 0;
  /* border-left: 1px solid #e4e4e4; */
  background: #000000;
  color: white;
}
.rightCata {
  border-right: solid 1px #e4e4e4;
  border-bottom: solid 1px #e4e4e4;
  /* border-left: 1px solid #e4e4e4; */

  background: #3f444a;
}
</style>